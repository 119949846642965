<template>
  <div class="accordion mb-6">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" role="tab">
        <b-button block size="sm" class="btn-collapse" @click="open = !open">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <b-row>
              <b-col cols="12" class="d-flex align-items-center">
                <h6 class="mb-0 font-weight-bolder">
                  Phần {{ section.order }}: {{ section.name }}
                </h6>
              </b-col>
            </b-row>
            <div class="d-flex">
              <b-button
                size="sm"
                class="icon-chevron"
                @click.stop="open = !open"
              >
                <v-icon class="p-0" size="24"
                  >mdi-{{ open ? 'chevron-up' : 'chevron-down' }}</v-icon
                >
              </b-button>
            </div>
          </div>
        </b-button>
      </b-card-header>
      <b-collapse
        :id="`accordion-${id}`"
        v-model="open"
        visible
        accordion="my-accordion"
        role="tabpanel"
      >
        <b-card-body>
          <template-table
            :data="section.questions"
            :column="headers"
            :selectAction="false"
            :tableAction="true"
            :searchAction="false"
            :pagingAction="false"
          >
            <template v-slot:body="{ item }">
              <td>{{ item.code }}</td>
              <td class="cell-overflow">
                <div class="text-overflow">
                  {{ item.name }}
                </div>
              </td>
              <td class="cell-overflow">
                <div
                  v-for="(answerName, index) in getAnswerName(item.result)"
                  :key="index"
                  class="text-overflow"
                >
                  {{ answerName }}
                </div>
              </td>
              <td class="text-center">
                {{ getAnswerPoint(item.result) }}
              </td>

              <td>
                <b-button
                  v-if="shouldShowIndexIcon(item.result)"
                  class="btn btn-edit"
                  size="sm"
                  type="button"
                  @click="viewQuestion(item)"
                >
                  <span class="svg-icon mr-0">
                    <inline-svg
                      src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                    />
                  </span>
                </b-button>
              </td>
            </template>
          </template-table>
        </b-card-body>
      </b-collapse>
      <ModalEditSurvey
        v-if="showModal"
        :id="id"
        :type="typeModal"
        :question="questionDetail"
        :patientId="patientId"
        :section="section"
        :accountId="accountId"
        :ownPackage="ownPackage"
        @reset="resetModal"
      />
    </b-card>
  </div>
</template>

<script>
import { MODAL_TYPE } from '@/core/plugins/constants';

export default {
  name: 'CollapseQuestionSection',
  components: {
    ModalEditSurvey: () =>
      import('@/view/pages/survey_quize/components/ModalEditSurvey.vue'),
  },
  props: {
    patientId: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    section: {
      type: Object,
      default: () => {},
    },
    accountId: {
      type: String,
      default: null,
    },
    ownPackage: {
      type: String,
      default: null,
    },
  },
  computed:{
     shouldShowIndexIcon() {
      return (result) => {
        return result.length ===0 ;
      };
    },
  },
  data() {
    return {
      isempty:true,
      loading: false,
      showModal: false,
      detail: null,
      questionDetail: null,
      typeModal: null,
      open: true,
      headers: [
        {
          key: 'code',
          label: 'Mã câu hỏi',
          sortable: false,
          style: {
            width: '10%',
          },
        },
        {
          key: 'name',
          label: 'Câu hỏi',
          sortable: false,
          style: {
            width: '40%',
          },
        },
        {
          key: 'answer',
          label: 'Câu trả lời',
          sortable: false,
          style: {
            width: '40%',
          },
        },
        {
          key: 'point',
          label: 'Điểm',
          sortable: false,
          class: 'text-center',
          style: {
            width: '10%',
          },
        },
      ],
    };
  },

  methods: {
    getAnswerName(result) {
      if (result && result.length) {
        return result.map((el) => el.answerName || el.content);
      }

      return [];
    },
    getAnswerPoint(result) {
      if (result && result.length) {
        let point = 0;
        result.forEach((el) => {
          el.point && (point += el.point);
        });
        return point ? point : null;
      }
      return null;
    },
    resetModal() {
      this.typeModal = null;
      this.showModal = false;
      this.questionDetail = null;
    },
    async viewQuestion(item) {
      this.typeModal = MODAL_TYPE.DETAIL;
      this.questionDetail = item;
      this.showModal = true;
      this.$store.commit(`context/setModal`, true);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-collapse {
  background-color: #fff !important;
  border-color: #fff !important;
  padding: 0.5rem 1.25rem;
}

.icon-chevron {
  background-color: #fff !important;
  border-color: #fff !important;
}

.btn-edit::v-deep {
  background: #def2f1 !important;
  border-color: #def2f1 !important;
  color: #008479 !important;
}
.btn-delete::v-deep {
  background: #ffebef !important;
  border-color: #ffebef !important;
  color: #ff5756 !important;
}

.accordion:last-child {
  margin-bottom: 0 !important;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
}
.cell-overflow {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
}
</style>
